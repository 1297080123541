<template>

  <div class="row" ref="requestView">

    <div class="col-12">

      <card>
        <div slot="header">
          <h4 class="card-title">
            {{ formTitle }}
          </h4>
        </div>

        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="typo-line">
                  <p>
                    <span class="category">First name</span>
                    {{ getValueOrDash(requestDetails, 'first_name') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Last name</span>
                    {{ getValueOrDash(requestDetails, 'last_name') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Email</span>
                    {{ getValueOrDash(requestDetails, 'email') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Project</span>
                    {{ getValueOrDash(requestDetails, 'project') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Complete </span>
                    {{ getValueOrDash(requestDetails, 'answered')}}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Tracking Number</span>
                    {{ getValueOrDash(requestDetails, 'track_id') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Shipping label</span>
                    <template v-if="getValueOrDash(requestDetails, 'shipping_label') != '-'">
                      <a  v-tooltip.top-center="'Download PDF'"
                          class="btn-danger btn-simple btn-link"
                          target="_blank"
                          :href="getUrl(getValueOrDash(requestDetails, 'shipping_label'))"><i class="fas fa-download shipping-label-actions"></i></a>

<!--                      <a  v-tooltip.top-center="'Print'"-->
<!--                          class="btn-danger btn-simple btn-link"-->
<!--                          @click="printFile(getValueOrDash(requestDetails, 'shipping_label'))"><i class="fas fa-print shipping-label-actions"></i></a>-->
                    </template>
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="typo-line">
                  <p>
                    <span class="category">Address1</span>
                    {{ getValueOrDash(requestDetails, 'address1') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Address2</span>
                    {{ getValueOrDash(requestDetails, 'address1') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">City</span>
                    {{ getValueOrDash(requestDetails, 'city') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">State</span>
                    {{ getValueOrDash(requestDetails, 'state') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Zipcode</span>
                    {{ getValueOrDash(requestDetails, 'zipcode') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Product damaged ?</span>
                    {{ getValueOrDash(requestDetails, 'damaged_product') == 1 ? 'Yes' : 'No' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-right">

        </div>
      </card>


    </div>

  </div>
</template>
<script>

export default {

  components: {},

  data() {
    return {
      id: undefined,
      formTitle: "",
      submitting: false,

      requestDetails: null,

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.contactUsView
    });

    this.id = this.$route.params['id'];
    this.formTitle = "Request Details";
    if (this.id !== undefined) {
      this.getRequest();
    } else {
      this.$notify({
        message: "Request Not Found",
        timeout: 2000,
        type: 'danger'
      });
      this.loader.hide();
    }
  },


  methods: {

    getRequest() {
      this.axios.post("requests/get", {id: +this.id}).then((response) => {
        this.requestDetails = response.data;
        this.requestDetails.project = this.requestDetails.project.project_id
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$notify({
            message: "Request Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    getValueOrDash(obj, key) {
      if (obj && obj[key]) {
        return obj[key];
      }
      return "-";
    },
    getUrl(value) {
      return process.env.VUE_APP_SERVER_IMAGE_URL + value;
    },
    printFile(file){
      window.print(this.getUrl(file));
    }
  }
}
</script>

<style>
.shipping-label-actions{
  font-size: 30px;
  margin-right: 20px;
}
</style>
