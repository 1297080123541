<template>

  <div class="row" ref="sendResponseForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div v-if="request && request.answered === 'Yes'" class="col-12">
                <div role="alert" class="alert alert-request-custom"><!----><span data-notify="message"><span><b>  Note: </b> This request is complete   -
                <router-link   v-if="$store.getters['auth/checkAccess']('request-responses/get')"
                               style="color: white"
                               :to="'/requests/responses/'+ request.id">
                  View details
                </router-link></span></span></div>
              </div>
              <div class="col-md-12 col-sm-12">
               <div class="row">
                 <div class="col-5">
                   <ValidationProvider
                     vid="sender_email"
                     rules="email"
                     name="The Sender Email"
                     v-slot="{ passed, failed,errors }">
                     <fg-input type="email"
                               :error="failed ? errors[0]: null"
                               label="Sender Email"
                               name="sender_email"
                               fou
                               v-model="formData.sender_email">
                     </fg-input>
                   </ValidationProvider>
                   <ValidationProvider
                           vid="subject"
                           rules=""
                           name="The Subject"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Subject"
                               name="subject"
                               fou
                               v-model="formData.subject">
                     </fg-input>
                   </ValidationProvider>
                   <ValidationProvider
                     vid="subject"
                     rules=""
                     name="The Tracking Number"
                     v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Tracking Number"
                               name="trackingNumber"
                               fou
                               v-model="formData.track_id">
                     </fg-input>
                   </ValidationProvider>
                 </div>
                 <div class="col-7">
                   <div class="request-info">
                     <h6 class="request-title">Request Info:</h6>
                     <div class="row">
                        <div class="col-6">
                          <div class="request-item">
                            <p class="request-label">First name:</p>
                            <p class="request-value">{{request.first_name}}</p>
                          </div>
                          <div class="request-item">
                            <p class="request-label">Last name:</p>
                            <p class="request-value">{{request.last_name}}</p>
                          </div>
                          <div class="request-item">
                            <p class="request-label">Email:</p>
                            <p class="request-value">{{request.email}}</p>
                          </div>
                          <div class="request-item">
                            <p class="request-label">Project:</p>
                            <p v-if="request.project" class="request-value">{{request.project.name}}</p>
                          </div>
                          <div class="request-item">
                            <p class="request-label">phone:</p>
                            <p class="request-value">{{request.phone}}</p>
                          </div>
                        </div>
                       <div class="col-6">
                         <div class="request-item">
                           <p class="request-label">Address1:</p>
                           <p class="request-value">{{request.address1}}</p>
                         </div>
                         <div class="request-item">
                           <p class="request-label">Address2:</p>
                           <p class="request-value">{{request.address2}}</p>
                         </div>
                         <div class="request-item">
                           <p class="request-label">City:</p>
                           <p class="request-value">{{request.city}}</p>
                         </div>
                         <div class="request-item">
                           <p class="request-label">State:</p>
                           <p class="request-value">{{request.state}}</p>
                         </div>
                         <div class="request-item">
                           <p class="request-label">Zipcode:</p>
                           <p class="request-value">{{request.zipcode}}</p>
                         </div>
                       </div>
                     </div>

                   </div>
                 </div>
               </div>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Email Body</label>
                  <editor
                    v-model="formData.email_body"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>

              </div>
              <div class="col-md-12 col-sm-12">

                <div class="form-group">
                  <label>File</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{getBannerImageInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-pdf-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.file"
                  >
                  </prime-pdf-uploader>
                </div>
                <div v-if="formData.shipping_label">
                  <div class="form-group">
                    <checkbox v-model="send_shipping_label">Send Shipping label as attachment</checkbox>
                  </div>
                  <a :href="getUrl(formData.shipping_label)" target="_blank" style="font-size: 44px;">
                    <i class="fas fa-file-pdf pdf-file-icon p-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/requests/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip, Checkbox} from 'element-ui';
import FormGroupTextArea, {ImageUploader} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';
import PrimePdfUploader from "../../components/PrimePdfUploader";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    PrimePdfUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    Checkbox,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      request:{},
      formData: {
        sender_email: "",
        subject: "",
        email_body: "",
        file: "",
        request_id: undefined,
        track_id:null,
        shipping_label:null,
      },
      send_shipping_label: false,
    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.sendResponseForm
    });
    this.formData.request_id = this.$route.params['id'];
    let data = {
      id: this.formData.request_id
    }
    this.axios.post("requests/get", data).then((response) => {
      this.request = response.data;
      if(this.request.track_id){
        this.formData.track_id = this.request.track_id;
      }
      if(this.request.shipping_label){
        this.formData.shipping_label = this.request.shipping_label;
      }
      this.axios.post("request-responses/get-email-template", data).then((response) => {
        this.formData.sender_email = response.data.sender_email;
        this.formData.email_body = response.data.email_body;
        this.formData.subject = response.data.subject;
      }).catch((error) => {
        console.error(error);
      })


      if (this.formData.request_id !== undefined) {
        this.loader.hide();
      }

    }).catch((error) => {
      if (error.response.status === 404) {
        this.entityNotFoundError = true;
        this.$notify({
          message: "Request Not Found",
          timeout: 2000,
          type: 'danger'
        });
        this.loader.hide();
      } else {
        console.error(error);
      }
    })
  },


  methods: {
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let formData = this.formData;
      if (!this.send_shipping_label){
        formData.shipping_label = null;
      }
      request = this.axios.post("request-responses/create", formData);
      successMessage = "Response Sent Successfully";
      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/requests/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_image"]) {
        return this.mediaInfo["panel_image"];
      } else {
        return "";
      }
    },
    getUrl(value) {
      return process.env.VUE_APP_SERVER_IMAGE_URL + value;
    },
  }
}
</script>
<style>
.request-info{
  border: 1px solid;
  padding: 9px 10px 10px 7px;
}

.request-title{
  margin-bottom: 10px !important;
}
.request-item{
  display: flex;

}
.request-item p {
  font-size: 13px !important;
}
.request-label{
  width:100px;
  padding-left: 10px;
}
.request-value{
  flex-basis: 75%;
}
.alert-request-custom{
  background-color: #7cb124;
  color: white;
}
</style>
