<template>
  <div>
    <FileUpload  :name="getName()" :url="getUploadUrl()"
                 @upload="afterUpload"
                 @before-send="beforeSend"
                 :fileLimit="filelimits"
                 :multiple="isMultiple()"
                 accept=".pdf" :maxFileSize="maxFileSize">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
    <div v-if="!isMultiple()  && value" >
      <a :href="getUrl()+value" target="_blank">
        <i class="fas fa-file-pdf pdf-file-icon p-2"></i>
      </a>
      <i class="fas fa-trash pdf-delete-icon text-danger" @click="handleRemove"></i>
    </div>

    <div v-if="isMultiple() && value.length" class="d-flex justfy-content-start">
      <div v-for="(pdf, x) in value" :key="'pdf_'+x">
        <a :href="getUrl()+value.pdf" target="_blank">
          <i class="fas fa-file-pdf pdf-file-icon p-2"></i>
        </a>
        <i class="fas fa-trash pdf-delete-icon text-danger" @click="handleRemove(x)"></i>
      </div>
    </div>
  </div>



</template>

<script>

import {Upload, Dialog, Button} from 'element-ui';
import FileUpload from 'primevue/fileupload';


export default {

  name: "PrimePdfUploader",
  props: {
    elementNum:{
      default:1
    },
    maxFileSize:{
      default:1000000
    },
    buttonTitle: {
      default: "Click To Upload"
    },
    value: {},
    previewWidth: {
      type: String,
      default:"240px"
    },
    previewHeight: {
      type: String,
      default:"auto"
    },
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    FileUpload,
  },

  data() {
    return {
      pdfs:[],
      dialogVideoUrl: '',
      disabled: false,
      filelimits:1,
      dialogVisible: false
    };
  },
  mounted() {
    this.filelimits = this.elementNum;
  },
  methods: {
    beforeSend: function (event) {
      this.filelimits++;
      event.xhr.setRequestHeader('Authorization', "Bearer " + this.$store.getters["auth/token"]);
    },
    afterUpload: function (event) {
      let resp = JSON.parse(event.xhr.response);
      if(this.isMultiple()){
        this.pdfs = this.value;
        this.pdfs = this.pdfs.concat(resp.data.url);
        this.$emit("input", this.pdfs);
      }else{
        this.$emit("input", resp.data.url);
      }
    },

    handleRemove(x=1000) {
      if(!this.isMultiple()){
        this.$emit('input', "");
      }else{
        this.value.splice(x, 1);
        this.$emit("input", this.value);
      }
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    isMultiple(){
      return (this.elementNum != 1);
    },
    getUploadUrl(){
      if(this.elementNum == 1){
        return process.env.VUE_APP_SERVER_IMAGE_URL+'api/files/upload-pdf';
      }else{
        return process.env.VUE_APP_SERVER_IMAGE_URL+'api/files/upload-multiple-pdf';
      }
    },
    closePdfPreview(){
      this.dialogVideoUrl = null;
    },
    getName(){
      return (this.isMultiple())? 'pdf[]' : 'pdf';
    }
  },
}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}
.pdf-file-icon{
  font-size: 100px;
}
.pdf-delete-icon{
  cursor: pointer;
}
</style>
