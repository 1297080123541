<template>
  <div class="row" ref="requestResponseList">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Request Response List</h4>
          <div CLASS="card-body">
            <div class="">

              <div class="col-12" v-for="response in responses" style="margin-bottom: 20px">
                <el-collapse>
                  <el-collapse-item :title="'Subject: '+ response.subject + ' ( ' + response.created_at + ' )'" name="1">
                    <div class="row mt-4">
                      <div class="col-md-9">Sender : {{ response.sender_email }}</div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-9">Tracking Number : {{ response.track_id }}</div>
                    </div>
                    <div class="mt-4" v-html="response.email_body"></div>

                    <div class="d-flex">
                      <a v-if="response.file" :href="getUrl()+response.file" target="_blank">
                        <i class="fas fa-file-pdf pdf-file-icon p-2"></i>
                      </a>
                      <a v-if="response.shipping_label" :href="getUrl()+response.shipping_label" target="_blank">
                        <i class="fas fa-file-pdf pdf-file-icon p-2"></i>
                      </a>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>

            </div>
          </div>
        </div>

        <div>

        </div>


      </card>



    </div>

  </div>
</template>
<script>
import {Collapse, CollapseItem, TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    NLPagination,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  data() {

    return {
      responses: [],
      requestId: undefined,
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      url : process.env.VUE_APP_SERVER_IMAGE_URL
    }

  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.requestResponseList
    });
    this.requestId = this.$route.params['id'];
    if (this.requestId !== undefined) {
      let data = {
        request_id: this.requestId,
      }
      this.axios.post("request-responses/get", data).then((response) => {
        this.responses = response.data;
        console.log(this.responses)
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
      })

    }
  },
  methods:{
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
  }

}
</script>


<style>
.pdf-file-icon{
  font-size: 100px;
}
</style>
